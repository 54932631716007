import React from "react"
import { graphql } from "gatsby"
import { Cities } from "../components/cities"
import Layout from "../components/layout"
import style from "./index.module.scss"

const IndexPage = ({ data }) => (
  <Layout backgroundImage="bg-storefront.png">
    <section className={style.header}>
      <h1 className={style.headerText}>
        What can you do today<br />
        while stuck at home?
      </h1>
      <p className={style.detail}>
        Stay sane while supporting local businesses! They need our help!
      </p>
      <p className={style.detail}>
        Stay up-to-date with all the latest news, deals, and offers by spots in
        your area. Select a city below!
      </p>
    </section>
    <Cities cities={data.allCity.nodes} />
  </Layout>
)

export const query = graphql`
  query HomePageQuery {
    allCity: allGoogleSheetLocationsRow(filter: {verified: {eq: "Y"}}) {
      nodes {
        name
        id
        image: photo
        slug
        type: locationtype
      }
    }
  }
`

export default IndexPage
