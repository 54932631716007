import * as React from "react"
import * as PropTypes from "prop-types"
import { Link } from "gatsby"
import { Arrow } from "./arrow"
import style from "./cities.module.scss"
import WideImage from "./images/wide-image"

const cityImageProps = {
  position: "absolute",
  height: "100%",
  width: "100%",
  zIndex: "1",
  opacity: "60%",
}

const PlusIcon = () => (
  <svg
    width="62px"
    height="62px"
    viewBox="0 0 62 62"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Plus Icon</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <linearGradient
        x1="48.6562752%"
        y1="100%"
        x2="51.3437248%"
        y2="0%"
        id="linearGradient-1"
      >
        <stop stopColor="#01FFCD" stopOpacity="0.297448645" offset="0%" />
        <stop stopColor="#01FFCD" offset="100%" />
      </linearGradient>
    </defs>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Plus-Icon" fill="url(#linearGradient-1)">
        <g id="Rectangle">
          <rect
            opacity="0.400000006"
            x="26.5"
            y="0.5"
            width="10"
            height="61"
            rx="2"
          />
          <rect
            transform="translate(31.000000, 30.500000) rotate(90.000000) translate(-31.000000, -30.500000) "
            x="26"
            y="0"
            width="10"
            height="61"
            rx="2"
          />
        </g>
      </g>
    </g>
  </svg>
)

const City = ({ name, image, slug, type }) => {
  return (
    <li className={style.cityEntry}>
      <Link to={`/${slug}`}>
        <div className={style.cityContent}>
          <WideImage filename={image} alt="" style={cityImageProps} />
          <h2 className={style.cityName}>{name}</h2>
          <p className={style.cityPlacesLink}>
            {type === "city" ? "View city spots" : "View online options"}{" "}
            <Arrow className={style.cityPlacesArrow} />
          </p>
        </div>
      </Link>
    </li>
  )
}

City.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["city", "virtual"]).isRequired,
}

const CityInitial = () => (
  <li>
    <div className={style.cityContentInitial}>
      <h1>
        <em>Where</em> are you?
      </h1>
      <p>More cities coming soon.</p>
    </div>
  </li>
)

const CityFinal = () => (
  <li className={style.cityEntry}>
    <Link to="/list-your-business">
      <div className={style.cityContent}>
        <WideImage
          alt=""
          filename="bg-wave3.png"
          style={{ ...cityImageProps, opacity: "100%" }}
        />
        <div>
          <PlusIcon />
        </div>
        <h1 className={style.cityRequestTitle}>Request your city</h1>
        <p className={style.cityPlacesLink}>
          List your Business <Arrow className={style.cityPlacesArrow} />
        </p>
      </div>
    </Link>
  </li>
)

export const Cities = ({ cities }) => {
  const cityElements = cities.map(city => (
    <City name={city.name} image={city.image} slug={city.slug} type={city.type} key={city.id}/>
  ))
  return (
    <ul className={style.cityList}>
      <CityInitial />
      {cityElements}
      <CityFinal />
    </ul>
  )
}
