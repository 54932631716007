import * as React from "react"

const Arrow = ({ color, height, width, className }) => (
  <svg
    className={className}
    height={height}
    viewBox="0 0 451.111 451.111"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m257.778 32.222-48.333 48.333 112.778 112.778h-322.223v64.444h322.222l-112.777 112.779 48.333 48.333 193.333-193.333z"
      fill={color}
    />
  </svg>
)

Arrow.defaultProps = {
  color: "#FFFFFF",
  height: 20,
  width: 20,
}

export { Arrow }
